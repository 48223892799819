import React from 'react';
import Header from '../shared/Header'
import Subtitle from '../shared/Subtitle'
import BlueSection from '../shared/BlueSection'
import Code from '../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
String webId = "mBS73C5TdTjthjF0";
String apiKey = "521026f468b9dadc2419e476356934d0";
String url = "https://firmamex.com/pdf/simple/default";

ObjectNode node = JsonNodeFactory.instance.objectNode();
node.put("url_doc", url);

SignmageServices services = new SignmageServices(webId, apiKey);
signmageServices.request(node.toString());
`}
        </Code>
    }
}


export function html() {
    return (
        <React.Fragment>
            <Subtitle>Por URL</Subtitle>
            <div>
                <p>
                    Para subir un documento por URL, se necesita que el documento
                    esté expuesto en una URL que sea accesible públicamente. Una vez que se cuente
                    con la URL, se para al atributo 'url_doc'
                </p>
            </div>

        </React.Fragment>
    )
}

export default {JS, html}