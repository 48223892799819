import React from 'react';
import Header from '../shared/Header'
import Subtitle from '../shared/Subtitle'
import BlueSection from '../shared/BlueSection'
import Code from '../shared/Code'

export function JS({codeLang}) {
        switch(codeLang) {            
            case 'python': {
                return <Code lang="javascript">
    {`
webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
                    `}
                    </Code>
            }
            default: {
                return <Code lang="javascript">
                    {`
String webId = "mBS73C5TdTjthjF0";
String apiKey = "521026f468b9dadc2419e476356934d0";

SignmageServices services = new SignmageServices(webId, apiKey);
                                    `}
                    </Code>
            }
        } 
}


export function html() {
    return (
        <React.Fragment>
            <Header>Autenticación</Header>
            <div>
                <p>
                    Para comenzar a usar el API por favor contacta a contacto@firmamex.com y te asignaremos
                    tus credenciales de desarrollador
                </p>
                <p>
                    Credenciales de ejemplo:
                </p>
                <BlueSection>
                    <div>
                        WebId: mBS73C5TdTjthjF0
                    </div>
                    <div>
                        API Key: 521026f468b9dadc2419e476356934d0
                    </div>
                </BlueSection>
                <p>
                    El WebId es público y lo puedes guardar en cualquier lugar, pero el API Key equivale a tu 
                    contraseña y le puede permitir a terceros solicitar documentos a tus usuarios. 
                    Guárdalo en un lugar seguro.
                </p>
                <p>
                    Cada solicitud que realices debe llevar un HMAC generado con los parámetros de la solicitud y 
                    el API Key. Para facilitar este proceso puedes utilizar el SDK que proporcionamos. En caso de que 
                    el SDK no esté disponible en el lenguaje de tu preferencia, por favor ponte en contacto con nosotros.
                </p>
                <Subtitle>Autenticación por medio del SDK</Subtitle>
                <p>
                    Para autenticarte por medio del SDK sólo es necesario pasarle tus credenciales de desarrollador
                    como se muestra en el panel lateral
                </p>
                <p>
                    En un ambiente de producción, se recomienda que cargues tus credenciales desde un archivo externo
                    o desde una variable de ambiente
                </p>
            </div>

        </React.Fragment>
    )
}

export default {JS, html}