import React, {useRef, useEffect} from 'react';
import Prism from 'prismjs'

export default function Code({lang, children}) {

    const codeEl = useRef(null)


    useEffect(() => {
        console.log('rendered ' + lang)
        Prism.highlightElement(codeEl.current)
    })

    return (
        <pre>
            <code className={`language-${lang}`} ref={codeEl}>
                {children}
            </code>
        </pre>
    )
}