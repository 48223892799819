import React from 'react';
import Header from '../shared/Header'

export default function Intro() {
    return (
        <React.Fragment>
            <Header>
                Introducción
            </Header>
            <div>
                <p>
                    El API de Firmamex permite utilizar todas las funciones que se encuentran en el portal de firma
                    para integrarlas en tus flujos según sea necesario                        
                </p>
                <p>
                    Puedes comenzar a probar sin ningún compromiso
                </p>
            </div>
        </React.Fragment>
    )
}