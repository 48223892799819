

export const MENU = [{
    name: 'Introducción',
    path:'/'
}, {
    name: 'Autenticación',
    path:'/autenticacion'
}, {
    name:'Documento simple',
    path: '/documento',
    items: [
        {
            name: 'Desde url',
            path: '/url'
        }
    ]
}]