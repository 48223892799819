import React, {useState} from 'react';
import MenuItem from './shared/MenuItem'
import {MENU} from './Consts'
import Intro from './pages/Intro'
import Autenticacion from './pages/Autenticacion'
import DocumentoSimpleUrl from './pages/DocumentoSimpleUrl';
import DocumentoSimple from './pages/DocumentoSimple'
import { Route } from "react-router-dom";
import {FormGroup, Input} from 'reactstrap'

export default function Main() {

    const [codeLang, setCodeLang] = useState('java')

    function handleCodeLangChange(event) {
        setCodeLang(event.target.value)
    }

    return (
        <div className="MainContainer">
            <div className="Sidebar">
                {
                    MENU.map((m,i) => (
                        <MenuItem 
                            key={i}
                            path={m.path}
                            items={m.items}
                            name={m.name}></MenuItem>
                    ))
                }
            </div>
            <div className="MainContent">
                <Route path="/docs" exact component={Intro}></Route>
                <Route path="/docs/autenticacion" exact component={Autenticacion.html}></Route>
                <Route path="/docs/documento" component={DocumentoSimple.html}></Route>
                <Route path="/docs/documento/url" exact component={DocumentoSimpleUrl.html}></Route>
            </div>
            <div className="Codebar">
                <FormGroup>
                  <Input type="select" 
                    value={codeLang}
                    onChange={handleCodeLangChange}
                    className="CodeLangSelect">
                    <option value="java">Java</option>
                    <option value="python">Python</option>
                  </Input>
                </FormGroup>
                <Route 
                    path="/docs/autenticacion"                      
                    render={() => 
                        <Autenticacion.JS codeLang={codeLang}></Autenticacion.JS>}
                ></Route>
                <Route path="/docs/documento/url"
                    render={() => 
                       <DocumentoSimpleUrl.JS codeLang={codeLang}></DocumentoSimpleUrl.JS> 
                }></Route>
            </div>
        </div>
    )

}