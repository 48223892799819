import React, {useState, useEffect} from 'react'

export function useToggle(initialValue) {
    const [value, setValue] = useState(initialValue);

    function toggle() {
        setValue(!value)
    }

    return [value, toggle]
}