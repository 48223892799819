import React from 'react'
import {Link} from 'react-router-dom'
import {Collapse} from 'reactstrap';
import {useToggle} from './Hooks'

export default function MenuItem({name, path, items}) {

    const [showItems, toggle] = useToggle(false);

    return (
        <div>
            {
                !items || items.length === 0 ?
                <div className="MenuItem">
                    <Link to={`/docs${path}`}>
                        {name}
                    </Link>
                </div> :
                <div className="MenuItem">
                    <Link to={`/docs${path}`} onClick={toggle}>
                        {name}
                    </Link>
                    <Collapse isOpen={showItems}>
                        {
                            items.map(i => (
                                <Link key={name} to={`/docs${path}${i.path}`} className="MenuItem">
                                    {i.name}
                                </Link>
                            ))
                        }
                    </Collapse>
                </div>
            }            
        </div>
    )
}