import React from 'react';
import Header from '../shared/Header'
import Subtitle from '../shared/Subtitle'
import BlueSection from '../shared/BlueSection'
import Code from '../shared/Code'
import {Link} from 'react-router-dom'

export function html() {
    return (
        <React.Fragment>
            <Header>Subir documento</Header>
            <div>
                <p>
                    El uso más sencillo del API es subir un documento para firmar sin ninguna otra opción.
                </p>
                <BlueSection>
                    <p>
                        Al subir un documento de esta manera, el documento puede ser firmado por cualquier
                        persona que tenga la URL, en cualquier página y con cualquier tipo de trazo de firma.
                    </p>
                </BlueSection>
                <p>
                    Los métodos por los que puedes pasar el documento al API son los siguientes:                    
                </p>
                <ul>
                    <li>
                        <Link to="/docs/documento/url">URL</Link>
                    </li>
                </ul>
            </div>

        </React.Fragment>
    )
}

export default {html}